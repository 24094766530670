<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    ref="notificationDropdown"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notificationCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <li class="scrollable-container media-list scroll-area" style="overflow-y: auto;">
        <a class="d-flex" href="javascript:void(0)" v-for="notification in notifications" v-bind:key="notification.id" @mouseover="readNotification(notification)" @click="notificationAction(notification)">
            <div class="media d-flex align-items-start">
                <div class="media-left" v-if="!notification.is_read" @mouseover="readNotification(notification)">
                    <div class="avatar bg-light-info">
                        <div class="avatar-content"><i class="avatar-icon" data-feather="alert-triangle"></i></div>
                    </div>
                </div>
                <div class="media-body" @mouseover="readNotification(notification)">
                    <p class="media-heading"><span class="font-weight-bolder" @mouseover="readNotification(notification)">{{ notification.title }}</span></p><small class="notification-text" @mouseover="readNotification(notification)">{{ notification.content }}<br><small class="float-right">{{ notification.time }}</small></small>
                </div>
            </div>
        </a>
        <p class="text-center mb-1 mt-1" v-if="notifications.length == 0">Notification empty.</p>
        <div class="text-center" v-if="nextPage != null">
            <button class="btn btn-secondary btn-sm mb-1 mt-1" v-on:click="loadNotification(currentPage + 1)">
              <feather-icon
                icon="ArrowDownIcon"
              />
            </button>
        </div>
    </li>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      @click="readNotification()"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        showNotif: false,
        notificationCount: 0,
        currentPage: 1,
        nextPage: false,
        notifications: 0,
        perfectScrollbarSettings: {
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }
    }
  },
  mounted() {
    this.initalize();

    this.$echo.channel('UnreadNotificationCountAdminUpdate').listen('.UnreadNotificationCountAdminUpdate', (payload) => {
      this.loadNotification();
      this.countUnreadNotification();
    });
  },
  methods: {
    initalize: function () {
        this.loadNotification();
        this.countUnreadNotification();
    },
    loadNotification: function (page = null) {
      var context = this;
      if (page == null) {
          page = context.currentPage;
      }
      context.currentPage = page;
      this.$http.get('/notification?page=' + page).then(function (response) {
        context.notifications = response.data.data.data
        context.nextPage = response.data.data.next_page_url;
      })
    },
    countUnreadNotification: function() {
      var context = this;
      setInterval(function () {
          context.$http.get('/notification/unread-count').then(function (response) {
            context.notificationCount = response.data.data;

            if (context.notificationCount > 0) {
              context.loadNotification();
              context.$refs.notificationDropdown.show()
            }
        })
      }, 10000);
    },
    readNotification: function (notification = null) {
        
        if (notification == null) {
            this.$http.post('/notification/mark-read/');
            this.notificationCount = 0;

            this.loadNotification();
        } else {
            if (!notification.is_read) {
                notification.is_read = true;
                this.$http.post('/notification/mark-read/' + notification.id);
                this.notificationCount -= 1;
            }
        }

    },
    notificationAction: function(notification) {
      if (notification.type == 'auction-request') {
        this.$router.push({ name: 'auctions.request' })
      }
      if (notification.type == 'commodity-withdraw') {
        this.$router.push({ name: 'commodity-withdraw.index' })
      }
    }
  }
}
</script>

<style>

</style>
